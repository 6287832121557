import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown,faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../styles/modallinks.scss'
import { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';


 export const Home = ({handleClose})=>(
<div className='modal-links-container'>
    <ul className='d-flex sub-links-modal'>
        <li onClick={handleClose}><span> HOME</span></li>
    </ul>
</div>
);

export const Tenting= ({showStateModal,handleClose})=>{

    const tents = ["BANDSHELLS","FOOD BOOTHS","CLEAR TENTS","CONSTRUCTION TENTS","CUSTOM BRANDED","CLEAR SPAN",
        "FRAME TENTS","GENESIS TENTS","HIGH PEAK","POLE TENTS","POP UP","ROUND TENTS","SADDLESPAN",
        "SAIL CLOTH","CABANAS"
    ];
    const navigate = useNavigate()
     const [showSubLinks,setShowSubLinks] = useState(false);
     const handleSubList = ()=>{
        setShowSubLinks(true)
     }
      const handleCloseSubList = ()=>{
        setShowSubLinks(false)
      }
      const handleShowTenting = ()=>{
        handleClose()

        navigate('/tentinglist')
      }
    

     
    return(
    <div className='modal-links-container'>
        <ul className='sub-links-modal' >
            <li onClick={handleShowTenting}><span>TENTING</span></li>
            <li>{!showSubLinks &&<FontAwesomeIcon icon={faAngleDown} onClick={handleSubList} />}
            {showSubLinks &&<FontAwesomeIcon icon={faAngleUp} onClick={handleCloseSubList} />}
            </li>
        </ul>
        {(showSubLinks && showStateModal) &&  <div className="nested-container"   >
       
        
            <ul className='sub-nested-links' >
                {tents.map((tentitem,index)=>(
                  <li key={index}>
                        {tentitem==='BANDSHELLS'?(
                        <Link className='link-styling' to='tent-rentals/bandshells'> 
                         <span style={{textTransform:'capitalize'}}>{tentitem.toLowerCase()}</span></Link>
                         ) : tentitem==='FOOD BOOTHS'?(
                         <Link className='link-styling' to='tent-rentals/food-booth-tents'> 
                          <span style={{textTransform:'capitalize'}}>{tentitem.toLowerCase()}</span>
                          </Link> )
                        : (  <span style={{textTransform:'capitalize'}}>{tentitem.toLowerCase()}</span>
                      )  }
                       </li>

                ))}
              
            </ul>
        
        </div>}
      
    </div>
)

}
export const Linens = ({showStateModal,handleClose})=>{
    const [showSubLinks,setShowSubLinks] = useState(false);
    const linenC = ["ABANICO","MAGNOLIA CHAMPAGNE","MOUGIN WATER","MESA MAUVE","MILLENNIAL","NAPKINS HEMSTICH GREY",
        "NAPKINS HEMSTITCH OATMEAL","PANAMA CELERY","PANAMA COBALT","PANAMA IVORY","PANAMA TAUPE","PANAMA WHITE",
        "MARBLE SKY BLUE","A LA MODE GOLD","AURA BEIGE", "AURA COFFEE","AURA IVORY","AURA VANILLA","BILLIES'S ROSES GARNET",
        "BILLIES'S ROSES GOLDEN ROSE","BILLIE'S ROSES MOONLIGHT" 
    ]
    const navigate = useNavigate()
    const handleSubList = ()=>{
       setShowSubLinks(true)
    }
     const handleCloseSubList = ()=>{
       setShowSubLinks(false)
     }
     const handleShowLinens = ()=>{
        handleClose()
        navigate('/linens')
     }
    return(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li onClick={handleShowLinens}><span>LINENS</span></li>
            <li>{!showSubLinks &&<FontAwesomeIcon icon={faAngleDown} onClick={handleSubList}  />}
            {showSubLinks && <FontAwesomeIcon icon={faAngleUp} onClick={handleCloseSubList} />}</li>
            </ul>
           
        {(showSubLinks && showStateModal) &&  <div className="nested-container" >
           
        <div className=''>
            <ul className='sub-nested-links' >
                {linenC.map((linensitem,index)=>(
                      <li key={index}><span style={{textTransform:'capitalize'}}>{linensitem.toLowerCase()}</span></li>

                ))}
              
            </ul>
        </div>
        </div>}
            
        

    </div>
)
}

export const Chairs = ({showStateModal,handleClose})=>{
    const [showSubLinks,setShowSubLinks] = useState(false);
    const chairsC = ["ARCRYLIC & CHROME BARSTOOL","ARCADIA","ELIO BAR STOOL","DYLAN CHAIR BEIGE","CAFE CHAIR","CLEAR COUPE","CLEAR PRISM BAR STOOL",
       "RATTAN WOOD","GHOST CHAIR","DYLAN CHAIR BEIGE","HAMPTON  WOODEN BEACH", "HAYWORTH GOLD BAR STOOLS","MADISONSQUARE DINING CHAIR","MADISON SQUARE BARSTOOL",
       "WOOD STEAT W/METAL FRAME BAR STOOL","NEKO DINING","MOD CHAIR-BLACK","MPD CHAIR-WHITE","TAYLOR CHAIR-BERRY","TAYLOR CHAIR-GOLD","TAYLOR CHAIR-GREY"
    ]
    const navigate = useNavigate();
    const handleSubList = ()=>{
        setShowSubLinks(true)
     }
      const handleCloseSubList = ()=>{
        setShowSubLinks(false)
      }
const handleShowChairs = ()=>{
    handleClose()
    navigate('/chairs')
}
    return(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li onClick={handleShowChairs}><span>CHAIRS</span></li>
            <li>{!showSubLinks &&<FontAwesomeIcon icon={faAngleDown} onClick={handleSubList}  />}
            {showSubLinks && <FontAwesomeIcon icon={faAngleUp} onClick={handleCloseSubList} />}</li>
            
        </ul>
        {(showSubLinks && showStateModal) &&  <div className="nested-container">
          
        <div className=''>
            <ul className='sub-nested-links' >
                {chairsC.map((chairssitem,index)=>(
                      <li key={index}><span style={{textTransform:'capitalize'}}>{chairssitem.toLowerCase()}</span></li>

                ))}
              
            </ul>
        </div>
        </div>}

    </div>
)
}

export const Glassware = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>GLASSWARE</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Lounge = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>LOUNGE FURNITURE</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Bars = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>BARS & ACCESSORIES</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Lightining = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>LIGHTINING</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Wedding = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>WEDDING DECOR</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Decorations = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>DECORATIONS & DISPLAY</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Flatware = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>FLATWARE</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)

export const Miscellaneous = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>MISCELLANEOUS</span></li>
            <li><FontAwesomeIcon icon={faAngleDown} /></li>
            
        </ul>

    </div>
)





export const Help = ()=>(
    <div className='modal-links-container'>
        <ul className='sub-links-modal'>
            <li><span>HELP</span></li>
           
        </ul>

    </div>
)




