import styles from '../styles/overview.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import React from 'react'

 const Overview = ({address,services}) => {
  return (
    <>
    <div className={`d-flex flex-row align-items-center py-2 ${styles.addressStyle}`}>
    <i class="bi bi-geo-alt"></i>
    <p>{address}</p>
    </div>
    <div className={`d-flex flex-row align-items-center  py-2 ${styles.addressStyle}`}>
    <i class="bi bi-list-ul"></i>
    <div className={`${styles.servicesList}`}>
     <p> <strong>Services:</strong>
    {services.map((service,index)=>(
      <>
      {service}
      </>
    ))}
    </p>
      </div>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
    <div className={`d-flex flex-row align-items-center  py-2 ${styles.addressStyle}`}>
    <i class="bi bi-cart3"></i>
    <p>Add to cart</p>
    </div>
    <div className={`d-flex flex-row  align-items-center py-2 ${styles.addressStyle}`}>
    <i class="bi bi-telephone"></i>
    <p>+1 999-999-0729</p>
    </div>
    </>
  )
}

export default Overview;
