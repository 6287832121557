import { ModalLinksMob} from './Icons.js'
import '../styles/navmodal.scss'
import { useRef } from 'react'

const NavModal = ({showStateModal, handleClose,handleDialogClose})=>{
 
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal')) {
      handleDialogClose();
    }
  };

 
     
    return(
       
        <div className={`modal  modal-container fade  ${showStateModal?'show':''}`} 
        style={{ display: showStateModal ? 'block' : 'none' }} tabindex="-1"
        onClick={handleOutsideClick}>
        <div className="modal-dialog  p-0 modal-full-height modal-dialog-scrollable"  onClick={(e) => e.stopPropagation()} 
        id="modalmobilescreen">
          <div className="modal-content modal-custom-content pb-5">
         
            <div class="modal-body modal-custom-body pb-3" >
              {ModalLinksMob.map((NavLink,index)=>(
                <NavLink key={index} showStateModal={showStateModal} handleClose={handleClose}/>
              ))}
            </div>
          
          </div>
        </div>
      </div>
    )
}
export default NavModal