import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items:[],
    addItemStatus:{},
    cartTotal:0,
    savings:0,
    totalAmount:0
    
}

const shoppingCartSlice = createSlice({
    name:'cart',
    initialState,
    reducers:{
        addTocCart(state,action){
           
         const newItem =    action.payload
         const existingItem =    state.items.find(item=>item.category===newItem.category&&item.id===newItem.id)
            const statusKey = `${newItem.category}-${newItem.id}`;
         if(existingItem){
            existingItem.quantity++; 
            state.addItemStatus[statusKey] = "updated"
            state.cartTotal += newItem.price
          
           
            
            
            
          
         }
         else{
            state.items.push({
                ...newItem,
                quantity: 1,
               
            })
            state.addItemStatus[statusKey] = "success"
            state.cartTotal += newItem.price
            
         }
       

    
        
            

        },
        removeItem(state,action){
          const {category,id } =   action.payload
         const deleteItem =  state.items.find(item=> item.category===category&& item.id===id)
         const updatedItems =    state.items.filter(item=>!(item.category===category&&item.id===id))
            state.items = updatedItems
            const statusKey = `${category}-${id}`
            
            if (state.addItemStatus.hasOwnProperty(statusKey)) {
                delete state.addItemStatus[statusKey];
              }
              if(deleteItem){
              state.cartTotal -= deleteItem.price
              }

        }

    } 
})


export  const shoppingCartActions = shoppingCartSlice.actions;
export default  shoppingCartSlice.reducer;