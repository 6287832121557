import { Outlet } from "react-router-dom";


const ChairsComp = ()=>{
    return(
        <Outlet/>

    )
}

export default ChairsComp;