
import { NavLink,useLocation } from "react-router-dom";
import ProductServiceDetails from "./ProductServiceDetails";
import TopImages from "./TopImages"


const ProductPartyDescrp = ({actualData})=>{
  const {pathname} = useLocation()
  const url = pathname.split('/').filter(x=>x)

 
  
   
    return(
        <>
        <div className="container my-3">
          <div className='d-flex flex-row justify-content-center align-items-center'>
          <NavLink to='/'>HOME</NavLink>
          {url.map((name,index)=>{
           let barcrumn = ''
            barcrumn += `/${name}`
            let  breadcrumbPath = `/${url.slice(0, index + 1).join('/')}`;
            if (name === 'party-services') {
              breadcrumbPath = `/party-services/${url[index + 1]}`;
            }
            return(
              <>
             { index===url.length-1?<span>{barcrumn.replace('-',' ').toUpperCase()}</span>: 
             <NavLink to={breadcrumbPath}>
              {barcrumn.replace('-',' ').toUpperCase()}</NavLink>}
            
             
             </>

          )})}
          </div>
        </div>

      <TopImages images={actualData.topimages} title={actualData.title}/>
     <ProductServiceDetails actualData={actualData}/>
      </>
    )
}

export default ProductPartyDescrp;