import {Icon1,Icon2,Icon3,Icon4,Icon5,Icon6,Icon7,Icon8,Icon13} from './SvgCtegoryIcons.js'
import {Home,Tenting, Linens,Chairs,Glassware ,Lounge,Bars,Lightining,Wedding ,Decorations,Flatware,Miscellaneous,
    Help} from './ModalLinks.js'


export const icons = [Icon1,Icon2,Icon3,Icon4,Icon5,Icon6,Icon7,Icon8,Icon13];

export const ModalLinksMob = [Home,Tenting,Linens, Chairs,Glassware ,Lounge,Bars,Lightining,Wedding ,Decorations,Flatware,Miscellaneous,
    Help];

