
import BannerImg from "./BannerImg";
import CategoriesPage from "./CategoriesPage";

import ImagesComp from "./ImagesComp";
import PartyServices from "./PartyServices";



import PopularCategories from "./PopularCategories";




const LandingPage = ()=>{
   
    return(
        <>
     <h1>Hiii from landing</h1>
     <BannerImg/>
     <PartyServices/>
        <CategoriesPage/>
   
   
      <PopularCategories/>
      
      
       
       

       </>
    )
    
}
export default LandingPage;