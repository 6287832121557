import MobileSearch from "./MobileSearch";
import UpperNav from "./UpperNav";
import NavModal from "./NavModal";
import { useState, useEffect} from "react";
import MobileFooter from "./MobileFooter";
import { Outlet} from 'react-router-dom';


const MobileNav = ()=>{
const [showStateModal,setShowStateModal] = useState(false);


    const showModal = ()=>{
        setShowStateModal(true)

    }
    const handleClose = ()=>{
        setShowStateModal(false)
    }
     const handleDialogClose = ()=>{
        setShowStateModal(false)

     }
    useEffect(() => {
        if (showStateModal) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
      }, [showStateModal]);
    return(
        <>
        <UpperNav showModal={showModal}/>
        <MobileSearch/>
        <Outlet/>


       
     <MobileFooter/>
      
      <NavModal showStateModal={showStateModal}  handleClose={handleClose} handleDialogClose={handleDialogClose}/>
        
        </>

    )
}

export default MobileNav;