
const BelowEmpty = ()=>{
    return (
        <div className="container-fluid " style={{height:"50vh", backgroundColor: '#e2e3e5',
            backgroundImage: 'linear-gradient(180deg, #e9ecef, #dee2e6)'}}>

        </div>

    )
}

export default BelowEmpty;